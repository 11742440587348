header.banner {
	.cta {
		margin-top: 5px;
	}
}
.phone-nav-container {
	margin-right: 5px;
	min-width: 200px;


	&:first-of-type {
		margin-right: 0;
	}


	.cta {
		padding: 12px 0;
		position: relative;
		.cta-info {
			padding: 0 20px;
			.cta-top {
				font-size: 26px;
				color: #fff;
				display: block;
				font-weight: 700;
				line-height: 1;
			}
			.cta-bottom {
				display: block;
				color: #fff;
				font-size: 18px;
				line-height: 1;
				font-weight: 300;
				margin: 2px 0;
			}
		}
	}
}
@media screen and (max-width: 1120px) {
	header.banner {
		.phone-nav-container {
			min-width: inherit;
			.phone-cta-plain,
			.cta {
				padding: 12px 0;
				.phone-cta-plain-info,
				.cta-info {
					.phone-cta-plain-number,
					.cta-top {
						font-size: 22px;
					}
					.phone-cta-plain-alternate,
					.cta-bottom {
						font-size: 14px;
					}
				}
			}
			
			
			&.professional-referrals,
			&.seeking-treatment,
			&.request-records {
				//display: none;
			}
		}
	}
	
	header.uk-active {
		.phone-nav-container {

			//max-width: 100px;
			position: relative;
			display:inline-block;
			float:none;
			margin-left: 0;
			left: 0;
			&.professional-referrals {
				display: none;
			}
			&.seeking-treatment {
				display: none;
			}
			&.request-records {
				display: none;
			}
		}
		.cta {
			padding: 6px 0;
			position: relative;
			.cta-info {

				.cta-top {
					font-size: 20px;
					color: #fff;
					display: block;
					font-weight: 700;
					line-height: 1;
				}
				.cta-bottom {
					display: block;
					color: #fff;
					font-size: 16px;
					line-height: 1;
					font-weight: 300;
					margin: 2px 0;
				}
			}
		}
	}
}

@media screen and (max-width: 960px) {
	header.banner {
		.gridl {
			display: flex;
			align-items: center;
			.phone-nav-container {
				top: auto;
				min-width: inherit;
			}
			.mobile-menu-btn-container {
				position: relative;
				margin: 0 0 0 auto;
			}
		}
	}
}

#mobile-off {
	.phone-nav-container {
		position: static;
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 5px;
		margin-top: 5px;
	}
}